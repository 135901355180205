<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header">
            <div class="content" style="margin-bottom:2.5rem">
              <b-field class="is-pulled-right">
                <b-radio-button
                  v-model="accountRegion"
                  native-value="GB"
                  type="is-link"
                  @input="updateAccountRegion"
                >
                  <span class="icon">🇬🇧</span>
                  <span>GB</span>
                </b-radio-button>

                <b-radio-button
                  v-model="accountRegion"
                  native-value="US"
                  type="is-link"
                  @input="updateAccountRegion"
                >
                  <span class="icon">🇺🇸</span>
                  <span>US</span>
                </b-radio-button>
              </b-field>
              <h2>
                Sales
                <span class="tag is-danger">SUPERDUPERUSER</span>
              </h2>

              <div class="box-tools is-pulled-right">
                <h4 class="has-text-link">
                  <button type="button" class="btn btn-box-tool has-text-dark" @click.prevent="getSubscriptionsInfo">
                    <i class="fas fa-sync" />
                  </button> 30-day total: {{ accountRegion === 'GB' ? '£' : '$' }}{{ totalSales.toFixed(2) }}
                </h4>
              </div>
            </div>
            <br class="clearfix">
            <div class="columns clearfix" style="overflow-x: scroll;">
              <div class="column is-6 content">
                <div id="salesChart" class="ct-chart" />
              </div>
              <div class="column is-6">
                <b-table
                  v-if="salesData"
                  :data.sync="salesData"
                  :paginated="true"
                  :per-page="7"
                  :mobile-cards="true"
                  :striped="true"
                  :selectable="false"
                  :sort="true"
                  default-sort-direction="desc"
                  default-sort="date"
                >
                  <template slot-scope="props">
                    <b-table-column field="date" label="Date" sortable>
                      <p>{{ formattedDate(props.row.date) }}</p>
                    </b-table-column>
                    <b-table-column :visible="accountRegion === 'GB'" field="sales" label="£">
                      <p>&pound;{{ props.row.sales }}</p>
                    </b-table-column>
                    <b-table-column field="salesusd" label="US$">
                      <p>${{ props.row.salesusd }}</p>
                    </b-table-column>
                    <b-table-column :visible="accountRegion === 'GB'" field="salesaud" label="AU$">
                      <p>${{ props.row.salesaud }}</p>
                    </b-table-column>
                    <b-table-column :visible="accountRegion === 'GB'" field="salesnzd" label="NZ$">
                      <p>${{ props.row.salesnzd }}</p>
                    </b-table-column>
                    <b-table-column :visible="accountRegion === 'GB'" field="saleseur" label="€">
                      <p>${{ props.row.saleseur }}</p>
                    </b-table-column>
                    <b-table-column :visible="accountRegion === 'GB'" field="saleszar" label="ZAR">
                      <p>R{{ props.row.saleszar }}</p>
                    </b-table-column>
                    <b-table-column field="merchsales" label="Merch">
                      <p>{{ accountRegion === 'GB' ? '£' : '$' }}{{ props.row.salesmerch }}</p>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered content">
                        <p>
                          <b-icon
                            custom-class="far"
                            pack="fa"
                            icon="frown"
                            size="is-large"
                          />
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div class="columns">
              <div class="column is-6">
                <b-field label="Select a date">
                  <b-datepicker
                    v-model="from"
                    placeholder="From..."
                    icon="calendar"
                    pack="mdi"
                    position="is-top-right"
                    @input="fromDateDidChange"
                  />
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Select a date">
                  <b-datepicker
                    v-model="to"
                    placeholder="To..."
                    icon="calendar"
                    pack="mdi"
                    position="is-top-right"
                    @input="dateDidChange"
                  />
                </b-field>
              </div>
            </div>
            <div>
              <div class="field is-pulled-left">
                <b-switch
                  v-model="payments"
                  type="is-success"
                  @input="dateDidChange"
                >
                  Payments
                </b-switch>
              </div>
              <button v-if="to" class="button is-danger is-pulled-right" style="margin-left: 5px;" @click="getDebtors">
                Debtors to date
              </button>
              <button v-if="from && to" class="button is-info is-pulled-right" style="margin-left: 5px;" @click="getXeroSubs">
                Subscriptions Journal
              </button>
              <button v-if="from && to" class="button is-link is-pulled-right" style="margin-left: 5px;" @click="getXeroMerch">
                Merch Sales Journal
              </button>
            </div>
            <div>
              <table class="table">
                <tr>
                  <th>&nbsp;</th><th v-show="accountRegion === 'GB'">
                    GBP
                  </th><th>USD</th><th v-show="accountRegion === 'GB'">
                    AUD
                  </th><th v-show="accountRegion === 'GB'">
                    NZD
                  </th><th v-show="accountRegion === 'GB'">
                    EUR
                  </th><th v-show="accountRegion === 'GB'">
                    ZAR
                  </th>
                </tr>
                <tr>
                  <th>Total subscriptions net:</th>
                  <td v-show="accountRegion === 'GB'">
                    &pound;<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.sales).toFixed(2) }}</span><span v-else>0.00</span>
                  </td>
                  <td>$<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.salesUSD).toFixed(2) }}</span><span v-else>0.00</span></td>
                  <td v-show="accountRegion === 'GB'">
                    A$<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.salesAUD).toFixed(2) }}</span><span v-else>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    NZ$<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.salesNZD).toFixed(2) }}</span><span v-else>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    €<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.salesEUR).toFixed(2) }}</span><span v-else>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    R<span v-if="salesTotals">{{ parseFloat(salesTotals.subscriptions.salesZAR).toFixed(2) }}</span><span v-else>0.00</span>
                  </td>
                </tr>
                <tr v-if="payments">
                  <th>Total subscriptions tax:</th>
                  <td v-show="accountRegion === 'GB'">
                    &pound;<span v-if="salesTotals">{{ salesTotals.subscriptions.tax }}</span><span v-else>0.00</span>
                  </td>
                  <td>$<span>0.00</span></td>
                  <td v-show="accountRegion === 'GB'">
                    A$<span>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    NZ$<span>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    €<span>0.00</span>
                  </td>
                  <td v-show="accountRegion === 'GB'">
                    R<span>0.00</span>
                  </td>
                </tr>
                <tr v-if="payments && accountRegion === 'GB'">
                  <th>Box Eight EU subscriptions:</th>
                  <td>&pound;<span v-if="salesTotals">{{ salesTotals.subscriptions.boxEight }}</span><span v-else>0.00</span></td><td>$<span>0.00</span></td><td>A$<span>0.00</span></td><td>NZ$<span>0.00</span></td><td>€<span>0.00</span></td><td>R<span>0.00</span></td>
                </tr>
                <tr>
                  <th>Total merch sales net:</th><td v-if="accountRegion === 'GB'">
                    &pound;<span v-if="salesTotals">{{ salesTotals.sales.sales }}</span><span v-else>0.00</span>
                  </td><td>$<span>0.00</span></td><td v-if="accountRegion === 'GB'">
                    A$<span>0.00</span>
                  </td><td v-if="accountRegion === 'GB'">
                    NZ$<span>0.00</span>
                  </td><td v-if="accountRegion === 'GB'">
                    €<span>0.00</span>
                  </td><td v-if="accountRegion === 'GB'">
                    R<span>0.00</span>
                  </td>
                </tr>
                <tr v-if="payments">
                  <th>Total merch sales tax:</th><td>&pound;<span v-if="salesTotals">{{ salesTotals.sales.tax }}</span><span v-else>0.00</span></td><td>$<span>0.00</span></td><td>A$<span>0.00</span></td><td>NZ$<span>0.00</span></td><td>€<span>0.00</span></td><td>R<span>0.00</span></td>
                </tr>
              </table>
            </div>
            <div class="content">
              <h3>Split</h3>
              <p>All sales, shared between packages. USD sales based on final amount only.</p>
              <b-switch v-model="lsten">
                Literacy Shed+ @{{ lsten?'10' : '100' }}%
              </b-switch>
              <table class="table">
                <tr>
                  <th>Product</th><th>Past Period</th><th>This Period</th><th>Multiplier</th>
                </tr><tr>
                  <th>Spelling</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.spelling.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.spelling.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.spelling && salesTotals.subscriptions.pastSplit.spelling">{{ (salesTotals.subscriptions.split.spelling / salesTotals.subscriptions.pastSplit.spelling).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Phonics</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.phonics.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.phonics.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.phonics && salesTotals.subscriptions.pastSplit.phonics">{{ (salesTotals.subscriptions.split.phonics / salesTotals.subscriptions.pastSplit.phonics).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Maths</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.pastSplit.maths.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.subscriptions.split.maths.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.maths && salesTotals.subscriptions.pastSplit.maths">{{ (salesTotals.subscriptions.split.maths / salesTotals.subscriptions.pastSplit.maths).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Literacy Shed +</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ (salesTotals.subscriptions.pastSplit.litshed * (lsten ? 0.1 : 1)).toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ (salesTotals.subscriptions.split.litshed * (lsten ? 0.1 : 1)).toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.subscriptions.split.litshed && salesTotals.subscriptions.pastSplit.litshed">{{ (salesTotals.subscriptions.split.litshed / salesTotals.subscriptions.pastSplit.litshed).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Merch</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.pastSplit.merch.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.split.merch.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.split.merch && salesTotals.sales.pastSplit.merch">{{ (salesTotals.sales.split.merch / salesTotals.sales.pastSplit.merch).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Training</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.pastSplit.training.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.split.training.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.split.training && salesTotals.sales.pastSplit.trianing">{{ (salesTotals.sales.split.training / salesTotals.sales.pastSplit.training).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr>
                  <th>Resources</th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.pastSplit.resources.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}<span v-if="salesTotals">{{ salesTotals.sales.split.resources.toLocaleString() }}</span><span v-else>0.00</span></td>
                  <td>x<span v-if="salesTotals && salesTotals.sales.split.merch && salesTotals.sales.pastSplit.resources">{{ (salesTotals.sales.split.resources / salesTotals.sales.pastSplit.resources).toFixed(2) }}</span><span v-else>0</span></td>
                </tr>
                <tr v-if="salesTotals">
                  <th>
                    TOTALS
                  </th>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}{{ (salesTotals.subscriptions.pastSplit.spelling + salesTotals.subscriptions.pastSplit.phonics + salesTotals.subscriptions.pastSplit.maths + salesTotals.subscriptions.pastSplit.litshed*(lsten ? 0.1 : 1) + salesTotals.sales.pastSplit.merch + salesTotals.sales.pastSplit.training + salesTotals.sales.pastSplit.resources).toLocaleString() }}</td>
                  <td>{{ accountRegion === 'US' ? '$' : '&pound;' }}{{ (salesTotals.subscriptions.split.spelling + salesTotals.subscriptions.split.phonics + salesTotals.subscriptions.split.maths + salesTotals.subscriptions.split.litshed*(lsten ? 0.1 : 1) + salesTotals.sales.split.merch + salesTotals.sales.split.training + salesTotals.sales.split.resources).toLocaleString() }}</td>
                  <td>
                    x{{ ((salesTotals.subscriptions.split.spelling + salesTotals.subscriptions.split.phonics + salesTotals.subscriptions.split.maths + salesTotals.sales.split.merch + salesTotals.sales.split.training + salesTotals.sales.split.resources + salesTotals.subscriptions.split.litshed*(lsten ? 0.1 : 1)) / (salesTotals.subscriptions.pastSplit.spelling + salesTotals.subscriptions.pastSplit.phonics + salesTotals.subscriptions.pastSplit.maths + salesTotals.sales.pastSplit.merch + salesTotals.sales.pastSplit.training + salesTotals.sales.pastSplit.resources + salesTotals.subscriptions.pastSplit.litshed*(lsten ? 0.1 : 1))).toFixed(2) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import { request } from '@/edshed-common/api'
// import $ from 'jquery'

export default {
  name: 'FinancialsView',
  components: {
    VueChartist,
    Chartist
  },
  data (router) {
    return {
      filteredSchoolsData: null,
      salesData: null,
      salesDataUSD: null,
      salesTotals: null,
      response: '',
      searchPhrase: '',
      from: null,
      to: null,
      payments: false,
      accountRegion: 'GB',
      lsten: true
    }
  },
  computed: {
    totalSales () {
      let t = 0
      if (!this.salesData) {
        return t
      }
      for (let i = 0; i < this.salesData.length; i++) {
        if (this.accountRegion === 'GB') {
          t += parseFloat(this.salesData[i].sales) || 0
          t += parseFloat(this.salesData[i].salesmerch) || 0
        } else if (this.accountRegion === 'US') {
          t += parseFloat(this.salesData[i].salesusd) || 0
          t += parseFloat(this.salesData[i].salesmerch) || 0
        }
      }
      return t
    }
  },
  mounted () {
    if (!this.$store.state.user.superduperuser) {
      this.$router.push('/noaccess')
    } else {
      this.getSubscriptionsInfo()
    }
  },
  methods: {
    updateAccountRegion () {
      console.log('RESET')
      this.salesData = null
      this.salesDataUSD = null
      this.salesTotals = null
      this.from = null
      this.to = null
      this.payments = false
      this.getSubscriptionsInfo()
    },
    getDebtors () {
      request('GET', 'superuser/getdebtors?to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'debtors.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getXeroSubs () {
      request('GET', 'superuser/xerosubs?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'subscriptions.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getXeroMerch () {
      request('GET', 'superuser/xeromerch?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&accountRegion=' + this.accountRegion, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'merch.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getSubscriptionsInfo () {
      console.log('GET INFO')
      request('GET', 'superuser/salesinfo?accountRegion=' + this.accountRegion, null)
        .then((response) => {
          const data = response.data
          this.salesData = data.sales
          this.salesDataUSD = data.usdsales
          this.renderChart()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    renderChart () {
      const series = []
      const dt = []
      const dtd = []
      const dta = []
      const dtn = []
      const dte = []
      const dtm = []
      for (let i = 0; i < this.salesData.length; i++) {
        const el = { x: moment(this.salesData[i].date), y: this.salesData[i].sales }
        dt.push(el)
        const eld = { x: moment(this.salesData[i].date), y: this.salesData[i].salesusd }
        dtd.push(eld)
        const elad = { x: moment(this.salesData[i].date), y: this.salesData[i].salesaud }
        dta.push(elad)
        const elnd = { x: moment(this.salesData[i].date), y: this.salesData[i].salesnzd }
        dtn.push(elnd)
        const eleur = { x: moment(this.salesData[i].date), y: this.salesData[i].saleseur }
        dte.push(eleur)
        const elzar = { x: moment(this.salesData[i].date), y: this.salesData[i].saleszar }
        dte.push(elzar)
        const elmer = { x: moment(this.salesData[i].date), y: this.salesData[i].salesmerch }
        dtm.push(elmer)
      }
      const s = { name: 'Sales', data: dt }
      series.push(s)
      const t = { name: 'Sales USD', data: dtd }
      series.push(t)
      const a = { name: 'Sales AUD', data: dta }
      series.push(a)
      const n = { name: 'Sales NZD', data: dtn }
      series.push(n)
      const e = { name: 'Sales EUR', data: dte }
      series.push(e)
      const z = { name: 'Sales ZAR', data: dte }
      series.push(z)
      const m = { name: 'Merch', data: dtm }
      series.push(m)
      this.chart = new Chartist.Line('#salesChart', { series },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 10,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          low: 0,
          height: 300,
          plugins: [
            // Chartist.plugins.legend()
          ]
        })
    },
    fromDateDidChange () {
      console.log('change')
      this.to = new Date(this.from)
      this.to.setDate(this.to.getDate() + 1)
      if (this.from && this.to) {
        console.log(this.from.toISOString())
        console.log(this.to.toISOString())
        const params = { from: this.from.toISOString(), to: this.to.toISOString(), payments: this.payments ? 1 : 0 }
        request('GET', 'superuser/salestotals?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&payments=' + (this.payments ? 1 : 0) + '&accountRegion=' + this.accountRegion, null)
          .then((response) => {
            const data = response.data
            this.salesTotals = data
            console.log('sales data: ' + data)
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    dateDidChange () {
      console.log('change')
      if (this.from && this.to) {
        console.log(this.from.toISOString())
        console.log(this.to.toISOString())
        const params = { from: this.from.toISOString(), to: this.to.toISOString(), payments: this.payments ? 1 : 0 }
        request('GET', 'superuser/salestotals?from=' + this.from.toISOString() + '&to=' + this.to.toISOString() + '&payments=' + (this.payments ? 1 : 0) + '&accountRegion=' + this.accountRegion, null)
          .then((response) => {
            const data = response.data
            this.salesTotals = data
            console.log('sales data: ' + data)
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    formattedDate (d) {
      return moment(d).format('DD/MM/YYYY')
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}

//   router.route('/superuser/markpaid')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suMarkPaid)
// router.route('/superuser/deactivatesubscription')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suMarkDeactivated)
// router.route('/superuser/setexpiry')
//   .post(auth.isAuthenticated, auth.requireSubscription, subscriptions.suSetExpiry)
// router.route('/superuser/setstage')
</script>
<style>

</style>
